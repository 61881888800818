var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.center)?_c('GmapMap',{ref:"mapRef",staticStyle:{"width":"100%","height":"260px"},attrs:{"center":_vm.center,"zoom":13,"map-type-id":"terrain","options":{
    zoomControl: false,
    mapTypeControl: false,
    scaleControl: false,
    streetViewControl: false,
    fullscreenControl: false,
    disableDefaultUi: false,
  }}},[_vm._l((_vm.markers),function(m,index){return _c('GmapMarker',{key:index,attrs:{"position":m.position,"clickable":"","draggable":false,"icon":_vm.getMarkerIcon(m)},on:{"click":function($event){return _vm.handleMarkerClick(m)}}})}),_c('GmapInfoWindow',{attrs:{"opened":!!_vm.selectedMarker,"position":_vm.selectedMarker && _vm.selectedMarker.position,"options":{
      pixelOffset: {
        width: 0,
        height: -35,
      },
    }},on:{"closeclick":function($event){_vm.selectedMarker = null}}},[(_vm.selectedMarker)?_c('div',{staticClass:"info-window-content"},[_c('h3',{on:{"click":function($event){return _vm.goToCourtDetail(_vm.selectedMarker.court)}}},[_vm._v(" "+_vm._s(_vm.selectedMarker.court.name)+" ")]),_c('p',[_vm._v(" "+_vm._s(_vm.getFreeCourt(_vm.selectedMarker.court))+" of "+_vm._s(_vm.selectedMarker.court.courtNum)+" court"+_vm._s(_vm.getFreeCourt(_vm.selectedMarker.court) > 1 ? "s" : "")+" free ")]),(
          _vm.getFreeCourt(_vm.selectedMarker.court) === 0 &&
          _vm.selectedMarker.availability.availableAt
        )?_c('p',[_vm._v(" Available at "+_vm._s(_vm._f("displayDate")(_vm.selectedMarker.availability.availableAt,"hh:mm A"))+" ")]):(
          _vm.getFreeCourt(_vm.selectedMarker.court) >= 1 &&
          _vm.selectedMarker.availability.unavailableAt
        )?_c('p',[_vm._v(" Available until "+_vm._s(_vm._f("displayDate")(_vm.selectedMarker.availability.unavailableAt,"hh:mm A"))+" ")]):_vm._e()]):_vm._e()])],2):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }