<template>
  <div>
    <vue-skeleton-loader
      type="rect"
      class="mb-8"
      width="100%"
      height="48px"
      color="rgba(255,255,255,0.22)"
      animation="fade"
    />
    <vue-skeleton-loader
      type="rect"
      class="mb-8"
      width="100%"
      height="48px"
      animation="fade"
      color="rgba(255,255,255,0.22)"
    />
    <vue-skeleton-loader
      type="rect"
      class="mb-8"
      width="100%"
      height="48px"
      animation="fade"
      color="rgba(255,255,255,0.22)"
    />
    <vue-skeleton-loader
      type="rect"
      class="mb-8"
      width="100%"
      height="48px"
      animation="fade"
      color="rgba(255,255,255,0.22)"
    />
    <vue-skeleton-loader
      type="rect"
      class="mb-8"
      width="100%"
      height="48px"
      animation="fade"
      color="rgba(255,255,255,0.22)"
    />
    <vue-skeleton-loader
      type="rect"
      class="mb-8"
      width="100%"
      height="48px"
      animation="fade"
      color="rgba(255,255,255,0.22)"
    />
    <vue-skeleton-loader
      type="rect"
      class="mb-8"
      width="100%"
      height="48px"
      animation="fade"
      color="rgba(255,255,255,0.22)"
    />
  </div>
</template>

<script>
export default {
  name: "list-loading",
};
</script>

<style lang="scss" scoped>
.mb-8 {
  margin-bottom: 8px;
}
</style>
